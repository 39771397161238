// src/components/AboutPage/CryptoResources.jsx
import React from 'react';
import './CryptoResources.css';

function CryptoResources() {
  const resources = [
    {
      title: 'Installing Wallets',
      description: 'Step-by-step guides on installing wallets like Metamask and Trust Wallet.',
      imageUrl: 'https://imgs.search.brave.com/-EN8AgUiq40lm9hhyHBCELO5f8jyl5yhpevpFv7Kf_0/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1wc2Qv/bWV0YW1hc2tfMjM0/MzYzLTU1NS5qcGc_/c2VtdD1haXNfaHli/cmlk',
      link: 'https://metamask.io/',
    },
    {
      title: 'Using Exchanges',
      description: 'Learn how to buy and sell cryptocurrency on popular exchanges like Coinbase.',
      imageUrl: 'https://imgs.search.brave.com/Z2mfokvqzja7f7KclCrZTweiQVO8gMrIAC4lnqMObNk/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzA0Lzg2LzI5LzQz/LzM2MF9GXzQ4NjI5/NDMzMV8yUHBRVmQx/RjBBa3B1eUhCSGt0/YjdjQkJ0MXY5Nkd3/Yy5qcGc',
      link: 'https://www.coinbase.com/',
    },
    {
      title: 'Cold Wallets',
      description: 'Explore cold storage options for enhanced security, like Ledger and Trezor.',
      imageUrl: 'https://imgs.search.brave.com/8mAZ4uCBHv4iR5b81zwRG7eE7BsotFl7uAMQPlTu3Ew/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9sZWRn/ZXItd3Atd2Vic2l0/ZS1zMy1wcmQubGVk/Z2VyLmNvbS91cGxv/YWRzLzIwMjMvMTEv/R2V0LWEtTGVkZ2Vy/LndlYnA',
      link: 'https://www.ledger.com/',
    },
    {
      title: 'Tracking Markets',
      description: 'Stay updated on prices and trends with CoinMarketCap and similar platforms.',
      imageUrl: 'https://imgs.search.brave.com/gN-G2k4FYrgrXoyTvdvFECaqJ41W-2ZNZ6dWa5PCJJU/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9zdDUu/ZGVwb3NpdHBob3Rv/cy5jb20vNjA2OTI2/NzAvNjI0MTIvaS80/NTAvZGVwb3NpdHBo/b3Rvc182MjQxMjQz/Nzgtc3RvY2stcGhv/dG8tdWtyYWluZS1v/ZGVzc2Etb2N0b2Jl/ci0yMDIxLXRyYWRp/bmcuanBn',
      link: 'https://www.coinmarketcap.com/',
    },
    {
      title: 'More Topics',
      description: 'Additional guides on security, staking, and blockchain basics.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/5/57/Binance_Logo.png',
      link: 'https://academy.binance.com/',
    },
  ];

  return (
    <section className="crypto-resources">
      <h2>Crypto Resources</h2>
      <p>Explore our comprehensive guides and resources to get started with cryptocurrency:</p>
      <div className="resources-grid">
        {resources.map((resource, index) => (
          <div className="resource-card" key={index}>
            <a href={resource.link} target="_blank" rel="noopener noreferrer">
              <img src={resource.imageUrl} alt={resource.title} className="resource-image" />
              <strong>{resource.title}</strong>
              <p>{resource.description}</p>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}

export default CryptoResources;
