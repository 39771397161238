import React from 'react';
import { Grid, Container, Typography, Card, CardContent, CardMedia, Box } from '@mui/material';
import { Hotel, Restaurant, Store, Tour } from '@mui/icons-material'; // Importación directa de íconos
import { useTranslation } from 'react-i18next';

const useCases = (t) => [
  {
    icon: <Hotel sx={{ fontSize: 50, color: 'white' }} />,
    title: t('useCases.hotels.title'),
    description: t('useCases.hotels.description'),
  },
  {
    icon: <Restaurant sx={{ fontSize: 50, color: 'white' }} />,
    title: t('useCases.restaurants.title'),
    description: t('useCases.restaurants.description'),
  },
  {
    icon: <Store sx={{ fontSize: 50, color: 'white' }} />,
    title: t('useCases.retailStores.title'),
    description: t('useCases.retailStores.description'),
  },
  {
    icon: <Tour sx={{ fontSize: 50, color: 'white' }} />,
    title: t('useCases.tourism.title'),
    description: t('useCases.tourism.description'),
  },
];

const UseCasesSection = () => {
  const { t } = useTranslation();
  const translatedUseCases = useCases(t);

  return (
    <Container sx={{ py: 10 }}>
      {/* Título de la sección */}
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 700,
          color: '#007BFF',
          mb: 4,
          textTransform: 'uppercase',
          letterSpacing: 3,
        }}
      >
        {t('useCases.title')}
      </Typography>

      {/* Introducción de la sección */}
      <Typography
        variant="body1"
        align="center"
        sx={{
          mb: 6,
          color: '#555',
          maxWidth: 800,
          margin: '0 auto',
          lineHeight: 1.8,
          fontSize: '1.1rem',
        }}
      >
        {t('useCases.intro')}
      </Typography>

      {/* Tarjetas de casos de uso */}
      <Grid container spacing={4} justifyContent="center">
        {translatedUseCases.map((useCase, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card
              sx={{
                textAlign: 'center',
                borderRadius: 3,
                background: 'linear-gradient(135deg, #007BFF, #FFD700)',
                boxShadow: 3,
                color: 'white',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                marginTop: 4,
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: 6,
                },
              }}
            >
              <CardMedia sx={{ mt: 3 }}>{useCase.icon}</CardMedia>
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 'bold',
                    color: 'white',
                    mb: 2,
                    textTransform: 'capitalize',
                  }}
                >
                  {useCase.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'white',
                    lineHeight: 1.6,
                    fontSize: '0.95rem',
                  }}
                >
                  {useCase.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Sección de Privacy & Compliance */}
      <Box
        sx={{
          backgroundColor: '#F5FAFF',
          borderLeft: '5px solid #007BFF',
          borderRadius: '12px',
          p: 4,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          mt: 8,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 700,
            color: '#007BFF',
            mb: 2,
          }}
        >
          {t('useCases.privacyTitle')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#333',
            lineHeight: 1.8,
            fontSize: '1rem',
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          {t('useCases.privacyDescription')}
        </Typography>
      </Box>
    </Container>
  );
};

export default UseCasesSection;
