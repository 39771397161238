// src/components/ContactPage/Map.jsx
import React from 'react';
import './Map.css';

function Map() {
  return (
    <section className="map-section">
      <h2>Find Us Here</h2>
      <div className="map-container">
        <iframe
          title="PayThai Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.734123456789!2d100.123456789!3d13.123456789!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29ed123456789%3A0x123456789abcdef!2s1234%20Sukhumvit%20Rd%2C%20Bangkok%2C%20Thailand!5e0!3m2!1sen!2sth!4v1234567890123"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
}

export default Map;
