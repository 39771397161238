import React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';

const stepsData = [
  { step: "1", description: "Open the Bitkub app and select 'Generate QR Code'." },
  { step: "2", description: "Enter the payment amount and select the cryptocurrency." },
  { step: "3", description: "Show the QR code to the customer for payment." },
];

function QRPayments() {
  return (
    <Box sx={{ py: 6, px: 3, textAlign: 'center', backgroundColor: '#F9FAFB' }}>
      {/* Título */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          background: 'linear-gradient(90deg, #009F5E, #00C26D)', // Degradado verde
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          mb: 4,
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Accept Payments with QR Codes
      </Typography>

      {/* Descripción */}
      <Typography
        variant="body1"
        sx={{
          mb: 5,
          color: '#000000', // Negro
          fontSize: '1.1rem',
          lineHeight: 1.6,
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        With Bitkub, you can easily generate QR codes for customers to make payments in cryptocurrency. 
        This makes transactions quick, simple, and secure.
      </Typography>

      {/* Tarjeta de QR dinámico */}
      <Card
        sx={{
          display: 'inline-block',
          padding: 3,
          borderRadius: 3,
          border: `2px solid #009F5E`, // Verde oscuro
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
          mb: 5,
        }}
      >
        <QRCodeCanvas
          value="https://bitkub.com" // URL o datos dinámicos
          size={150}
          bgColor="#FFFFFF"
          fgColor="#00C26D" // Verde claro para el QR
          level="Q"
        />
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            color: '#000000', // Negro
            fontSize: '0.9rem',
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          Example QR Code for Payment
        </Typography>
      </Card>

      {/* Pasos */}
      <Grid container spacing={2} justifyContent="center">
        {stepsData.map((step, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                backgroundColor: '#E0F7F1', // Fondo claro verde
                padding: 2,
                borderRadius: 2,
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                '&:hover': {
                  transform: 'scale(1.03)',
                  transition: 'transform 0.3s ease-in-out',
                },
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: '#009F5E', // Verde oscuro
                    color: '#FFFFFF',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    mx: 'auto',
                    mb: 2,
                  }}
                >
                  {step.step}
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#000000', // Negro
                    lineHeight: 1.5,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {step.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Botón de más información */}
      <Button
        variant="contained"
        sx={{
          mt: 5,
          px: 4,
          py: 1.5,
          backgroundColor: '#00C26D', // Verde claro
          color: '#FFFFFF',
          fontWeight: 'bold',
          fontSize: '1rem',
          borderRadius: '30px',
          '&:hover': {
            backgroundColor: '#009F5E', // Verde oscuro
          },
        }}
        onClick={() => window.location.href = '/learn-more'}
      >
        Learn More
      </Button>
    </Box>
  );
}

export default QRPayments;
