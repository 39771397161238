// src/components/AboutPage/Roadmap.jsx
import React from 'react';
import './Roadmap.css';

function Roadmap() {
  return (
    <section className="roadmap">
      <h2>Roadmap</h2>
      <div className="timeline">
        <div className="timeline-item">
          <div className="timeline-content">
            <h3>Q1 2025</h3>
            <p>Initial funding and marketing setup.</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-content">
            <h3>Q2 2025</h3>
            <p>Integration with local businesses and partnerships with key stakeholders.</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-content">
            <h3>Q3 2025</h3>
            <p>Launch of the mobile app for payment processing and crypto-wallet integration.</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-content">
            <h3>Q4 2025</h3>
            <p>Expansion of features to include instant conversion to stablecoins and enhanced security with multi-sig wallets.</p>
          </div>
        </div>
        <div className="timeline-item">
          <div className="timeline-content">
            <h3>2026</h3>
            <p>Full platform rollout across Thailand with additional features, including NFT marketplace and tourism integration.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
