import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserAgreement from './UserAgreement';
import PersonalDataManagement from './PersonalDataManagement';

const steps = ['Account Details', 'User Agreement', 'Personal Data'];

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    number: false,
  });
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [consentData, setConsentData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validatePassword = (password) => {
    setPasswordValidations({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      number: /[0-9]/.test(password),
    });
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const isPasswordValid = Object.values(passwordValidations).every((v) => v);

  const handleNextStep = () => {
    if (activeStep === 0) {
      if (email && isPasswordValid && password === confirmPassword) {
        setActiveStep((prev) => prev + 1);
      } else {
        alert(t('register.validation_error'));
      }
    } else if (activeStep === 1) {
      if (agreementChecked) {
        setActiveStep((prev) => prev + 1);
      } else {
        alert(t('register.agreement_required'));
      }
    } else if (activeStep === 2) {
      if (consentData) {
        setIsSubmitting(true);
        setTimeout(() => {
          setIsSubmitting(false);
          navigate('/login');
        }, 1500);
      } else {
        alert(t('register.consent_required'));
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prev) => prev - 1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '20px',
        gap: '40px',
        backgroundColor: '#FFFFFF',
      }}
    >
      {/* Left side */}
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          padding: '40px',
          backgroundColor: '#F9FAFB',
          borderRadius: '12px',
          maxWidth: '450px',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: '#007BFF',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '16px',
          }}
        >
          Step into the future with PayThai
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#4F4F4F',
            textAlign: 'center',
            maxWidth: '300px',
            lineHeight: '1.6',
          }}
        >
          Create your account and manage your crypto payments securely and efficiently.
        </Typography>
        <Box
          component="img"
          src={`${process.env.PUBLIC_URL}/assets/images/register-illustration.png`}
          alt="Register Illustration"
          sx={{ maxWidth: '100%', marginTop: '20px' }}
        />
      </Box>

      {/* Right side */}
      <Box
        sx={{
          maxWidth: '400px',
          width: '100%',
          padding: '30px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: 'calc(100vh - 80px)',
          overflowY: 'auto',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: '20px',
            color: '#007BFF',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {t('register.title')}
        </Typography>

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <>
            <TextField
              fullWidth
              label={t('register.email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label={t('register.password')}
              type="password"
              value={password}
              onChange={handlePasswordChange}
              margin="normal"
              required
            />
            <Box sx={{ marginY: 1 }}>
              <Typography variant="body2" color={passwordValidations.length ? 'green' : 'red'}>
                {t('register.password_length')}
              </Typography>
              <Typography variant="body2" color={passwordValidations.uppercase ? 'green' : 'red'}>
                {t('register.password_uppercase')}
              </Typography>
              <Typography variant="body2" color={passwordValidations.number ? 'green' : 'red'}>
                {t('register.password_number')}
              </Typography>
            </Box>
            <TextField
              fullWidth
              label={t('register.confirm_password')}
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              margin="normal"
              required
            />
          </>
        )}

        {activeStep === 1 && (
          <>
            <Box
              sx={{
                maxHeight: '300px',
                overflowY: 'auto',
                padding: '10px',
                border: '1px solid #ddd',
                borderRadius: '8px',
                backgroundColor: '#F9FAFB',
              }}
            >
              <UserAgreement />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreementChecked}
                  onChange={(e) => setAgreementChecked(e.target.checked)}
                />
              }
              label={t('register.agree')}
              sx={{ marginTop: 2 }}
            />
          </>
        )}

        {activeStep === 2 && (
          <Box
            sx={{
              maxHeight: '300px',
              overflowY: 'auto',
              padding: '10px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#F9FAFB',
            }}
          >
            <PersonalDataManagement
              onConsent={(data) => setConsentData(data)}
            />
          </Box>
        )}

        <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          {activeStep > 0 && (
            <Button onClick={handleBack} variant="outlined" sx={{ color: '#007BFF' }}>
              {t('register.back')}
            </Button>
          )}
          <Button
            onClick={handleNextStep}
            variant="contained"
            disabled={
              activeStep === 0
                ? !email || !isPasswordValid || password !== confirmPassword
                : activeStep === 1
                ? !agreementChecked
                : activeStep === 2 && !consentData
            }
            sx={{
              backgroundColor: '#007BFF',
              '&:hover': { backgroundColor: '#0056b3' },
            }}
          >
            {isSubmitting ? <CircularProgress size={16} color="inherit" /> : t('register.next')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
