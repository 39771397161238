import React from 'react';

function HeroB() {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden' }}>
      {/* Video de fondo */}
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      >
        <source src={`${process.env.PUBLIC_URL}/assets/videos/light-hero-banner.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Contenido */}
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          height: '100%',
          padding: '0 8%',
          zIndex: 1,
        }}
      >
        {/* Título */}
        <h1
          style={{
            fontSize: '3rem', // Tamaño predeterminado para escritorio
            fontWeight: 'bold',
            marginBottom: '15px',
            lineHeight: '1.2',
            fontFamily: "'Poppins', sans-serif",
            textAlign: 'left',
            maxWidth: '90%',
            whiteSpace: 'normal', // Permite que el texto haga salto de línea
            wordBreak: 'break-word', // Asegura que no se corten palabras
            background: 'linear-gradient(90deg, #00C26D, #009F5E)', // Degradado
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent', // Aplica el degradado solo al texto
          }}
          className="hero-title"
        >
          Buy & Sell Crypto <br /> with Bitkub Exchange
        </h1>

        {/* Subtítulo */}
        <p
          style={{
            fontSize: '1rem',
            fontWeight: '400',
            color: '#4A4A4A',
            marginBottom: '20px',
            lineHeight: '1.6',
            maxWidth: '600px',
            fontFamily: "'Poppins', sans-serif",
            textAlign: 'left',
          }}
          className="hero-subtitle"
        >
          Buy and sell Bitcoin, Ethereum, USDT, XRP, Dogecoin, Polkadot and more with Thai baht at Bitkub Exchange.
        </p>

        {/* Botón */}
        <button
          style={{
            padding: '12px 24px',
            fontSize: '1rem',
            color: 'white',
            backgroundColor: '#00C26D',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            fontWeight: 'bold',
            fontFamily: "'Poppins', sans-serif",
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s ease',
          }}
          onClick={() => window.location.href = '/signup'}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#009F5E')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#00C26D')}
        >
          Sign up for free
        </button>
      </div>

      {/* Media Queries */}
      <style>
        {`
          @media (max-width: 768px) {
            .hero-title {
              font-size: 2rem; /* Tamaño reducido para pantallas medianas */
              line-height: 1.3;
              max-width: 100%;
            }

            .hero-subtitle {
              font-size: 0.9rem; /* Subtítulo más compacto */
            }
          }

          @media (max-width: 480px) {
            .hero-title {
              font-size: 1.5rem; /* Tamaño reducido para pantallas pequeñas */
              line-height: 1.4;
              max-width: 100%;
            }

            .hero-subtitle {
              font-size: 0.8rem; /* Tamaño aún menor para el subtítulo */
            }

            button {
              padding: 10px 20px;
              font-size: 0.85rem;
            }
          }
        `}
      </style>
    </div>
  );
}

export default HeroB;
