// src/pages/About.jsx
import React from 'react';
import AboutDescription from '../components/AboutPage/AboutDescription';
import Roadmap from '../components/AboutPage/Roadmap';
import CryptoResources from '../components/AboutPage/CryptoResources';

function About() {
  return (
    <div>
      <AboutDescription />
      <Roadmap />
      <CryptoResources />
    </div>
  );
}

export default About;
