// src/pages/Bitkub.jsx
import React from 'react';
import Hero from '../components/BitkubPage/HeroB';
import Benefits from '../components/BitkubPage/Benefits';
import InstallationSteps from '../components/BitkubPage/InstallationSteps';
import QRPayments from '../components/BitkubPage/QRPayments';
import THBConversion from '../components/BitkubPage/THBConversion';
import '../assets/styles/Bitkub.css'; // Importa el nuevo archivo CSS

function Bitkub() {
  return (
    <div className="exclude-effects">
      <Hero />
      <Benefits />
      <InstallationSteps />
      <QRPayments />
      <THBConversion />
    </div>
  );
}

export default Bitkub;
