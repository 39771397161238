import React, { useState } from 'react'; // Esta debe ser la primera importación
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/global.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header'; // Header para escritorio y móvil
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Bitkub from './pages/Bitkub';
import Contact from './pages/Contact';
import ContactForm from './components/ContactForm/ContactForm'; 
import FAQ from './components/FAQ/FAQ';
import FeeCalculator from './components/FeeCalculator/FeeCalculator';
import UseCasesSection from './components/UseCasesSection/UseCasesSection'; // Importa la sección de casos de uso
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ForgotPassword from './components/Auth/ForgotPassword';
import UserProfile from './components/UserProfile';
import ProtectedRoute from './components/ProtectedRoute';
import ScrollToTopOnRouteChange from './components/ScrollToTopOnRouteChange';
import AnalyticsTracker from './components/AnalyticsTracker';
import { AuthProvider } from './context/AuthContext'; 
import './config/i18n';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Integración con Material-UI

const theme = createTheme({ // Configura un tema global para Material-UI
  palette: {
    primary: {
      main: '#007BFF', // Color primario
    },
    secondary: {
      main: '#FFD700', // Color secundario
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif', // Fuente global
  },
});

function App() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('en');

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <ThemeProvider theme={theme}> {/* Material-UI ThemeProvider */}
      <AuthProvider> {/* Envuelve la aplicación en el proveedor de autenticación */}
        <Router>
          <ScrollToTopOnRouteChange />
          <AnalyticsTracker />
          
          {/* Solo Header (para escritorio y móvil) */}
          <Header language={language} setLanguage={setLanguage} changeLanguage={changeLanguage} />
          
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/bitkub" element={<Bitkub />} />
              <Route path="/contact" element={<Contact />} /> 
              <Route path="/contactform" element={<ContactForm />} /> 
              <Route path="/faq" element={<FAQ />} />
              <Route path="/fee-calculator" element={<FeeCalculator />} />
              <Route path="/use-cases" element={<UseCasesSection />} /> {/* Nueva ruta para casos de uso */}
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} /> 

              {/* Ruta protegida para el perfil de usuario */}
              <Route
                path="/user-profile"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </main>
          <Footer />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
