import React from 'react';
import { Box, Typography, Grid, Link, IconButton } from '@mui/material';
import { FaFacebook, FaTwitter, FaLinkedin, FaYoutube, FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

function Footer() {
  const { t } = useTranslation();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#F9FAFB',
        color: '#333',
        padding: '40px 20px',
        borderTop: '1px solid #E0E0E0',
      }}
    >
      {/* Contenido Principal */}
      <Grid container spacing={4} justifyContent="center">
        {/* Contact Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', color: '#007BFF' }}>
            {t('footer.contact_us')}
          </Typography>
          <Typography variant="body2">PayThai Co., Ltd.</Typography>
          <Typography variant="body2">1234 Sukhumvit Road, Bangkok, Thailand</Typography>
          <Typography variant="body2">{t('footer.phone')}: +66 1234 6387</Typography>
          <Typography variant="body2">{t('footer.email')}: info@paythai.io</Typography>
        </Grid>

        {/* Products Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', color: '#007BFF' }}>
            {t('footer.products')}
          </Typography>
          {['bitkub_integration', 'qr_payments', 'thb_conversion', 'fee_calculator'].map((key) => (
            <Link
              component={RouterLink}
              to={
                key === 'fee_calculator'
                  ? '/fee-calculator' // Ruta especial para Fee Calculator
                  : `/${key}`
              }
              key={key}
              sx={{
                display: 'block',
                marginBottom: '8px',
                color: '#333',
                fontSize: '0.9rem',
                textDecoration: 'none',
                '&:hover': {
                  color: '#FFD700',
                },
              }}
            >
              {t(`footer.${key}`)}
            </Link>
          ))}
        </Grid>

        {/* Company Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', color: '#007BFF' }}>
            {t('footer.company')}
          </Typography>
          {['about_us', 'privacy_policy', 'careers'].map((key) => (
            <Link
              component={RouterLink}
              to={`/${key.replace('_', '-')}`}
              key={key}
              sx={{
                display: 'block',
                marginBottom: '8px',
                color: '#333',
                fontSize: '0.9rem',
                textDecoration: 'none',
                '&:hover': {
                  color: '#FFD700',
                },
              }}
            >
              {t(`footer.${key}`)}
            </Link>
          ))}
        </Grid>

        {/* Account Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', color: '#007BFF' }}>
            {t('footer.account')}
          </Typography>
          {['register', 'login'].map((key) => (
            <Link
              component={RouterLink}
              to={`/${key}`}
              key={key}
              sx={{
                display: 'block',
                marginBottom: '8px',
                color: '#333',
                fontSize: '0.9rem',
                textDecoration: 'none',
                '&:hover': {
                  color: '#FFD700',
                },
              }}
            >
              {t(`footer.${key}`)}
            </Link>
          ))}
          <Link
            component={RouterLink}
            to="/contactform"
            sx={{
              display: 'block',
              marginTop: '12px',
              fontWeight: 'bold',
              color: '#0044cc',
              textDecoration: 'none',
              '&:hover': {
                color: '#FFD700',
              },
            }}
          >
            {t('footer.register_business')}
          </Link>
        </Grid>
      </Grid>

      {/* Redes Sociales */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '40px',
        }}
      >
        {[
          { icon: <FaFacebook />, href: 'https://facebook.com' },
          { icon: <FaTwitter />, href: 'https://twitter.com' },
          { icon: <FaLinkedin />, href: 'https://linkedin.com' },
          { icon: <FaYoutube />, href: 'https://youtube.com' },
          { icon: <FaInstagram />, href: 'https://instagram.com' },
        ].map((social, index) => (
          <IconButton
            key={index}
            component="a"
            href={social.href}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: '#007BFF',
              margin: '0 8px',
              fontSize: '1.5rem',
              '&:hover': {
                color: '#FFD700',
              },
            }}
          >
            {social.icon}
          </IconButton>
        ))}
      </Box>

      {/* Legal Section */}
      <Box
        sx={{
          borderTop: '1px solid #E0E0E0',
          marginTop: '40px',
          paddingTop: '20px',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2" sx={{ color: '#555' }}>
          &copy; {new Date().getFullYear()} PayThai Co., Ltd. {t('footer.rights_reserved')}
        </Typography>
        <Typography variant="body2" sx={{ color: '#888', marginTop: '8px' }}>
          {t('footer.disclaimer')}
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
