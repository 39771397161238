// src/components/AboutPage/AboutDescription.jsx
import React from 'react';
import './AboutDescription.css';
import { FaBitcoin, FaRegHandshake, FaRocket } from 'react-icons/fa';

function AboutDescription() {
  return (
    <section className="about-description">
      <h2>About PayThai</h2>
      <div className="content-wrapper">
        <div className="icon-container">
          <FaRocket className="icon" />
        </div>
        <p>
          PayThai is a pioneering platform aimed at integrating cryptocurrency payments for businesses in Thailand. 
          Our mission is to provide a fast, secure, and low-fee solution for digital transactions, targeting both local businesses and the growing number of digital nomads and tourists who prefer using cryptocurrencies. 
          Through our platform, businesses can easily accept payments in cryptocurrencies like USDT, Cardano, and Bitcoin, and convert them into Thai Baht seamlessly.
        </p>
        <div className="icon-container">
          <FaRegHandshake className="icon" />
        </div>
        <p>
          PayThai’s goal is to simplify cryptocurrency transactions for Thai businesses, helping them attract new customers and reduce transaction costs associated with traditional payment methods. 
          We are committed to advancing the adoption of digital currencies in everyday life, enhancing financial freedom and accessibility for both businesses and customers.
        </p>
      </div>
    </section>
  );
}

export default AboutDescription;
