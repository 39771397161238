import React from 'react';
import { Grid, Container, Typography, Card, CardContent, CardMedia, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BlogSection = () => {
  const { t } = useTranslation();
  const articles = t('blog.articles', { returnObjects: true });

  return (
    <Container sx={{ py: 10 }}>
      {/* Título */}
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 700,
          color: '#007BFF',
          mb: 4,
        }}
      >
        {t('blog.title')}
      </Typography>

      {/* Tarjetas de Blog */}
      <Grid container spacing={4} justifyContent="center">
        {articles.map((article) => (
          <Grid item xs={12} sm={6} md={4} key={article.id}>
            <Card
              sx={{
                height: '100%', // Garantiza que todas las tarjetas tengan la misma altura
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between', // Separa el botón del contenido
                boxShadow: 3,
                borderRadius: 2,
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              <CardMedia
                component="img"
                image={article.image}
                alt={article.title}
                sx={{
                  height: 200, // Fija la altura de las imágenes para que todas sean consistentes
                  objectFit: 'cover', // Evita deformaciones
                }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    color: '#333',
                    mb: 2,
                  }}
                >
                  {article.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#555',
                    fontSize: '0.9rem',
                    lineHeight: 1.6,
                  }}
                >
                  {article.summary}
                </Typography>
              </CardContent>
              <Button
                href={article.link}
                target="_blank"
                variant="contained"
                sx={{
                  mx: 'auto',
                  mb: 2,
                  px: 4,
                  textTransform: 'capitalize',
                  backgroundColor: '#007BFF',
                  '&:hover': {
                    backgroundColor: '#0056b3',
                  },
                }}
              >
                {t('blog.readMore')}
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BlogSection;
