import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from '@mui/material';

function FeeCalculator() {
  const [amountUSD, setAmountUSD] = useState('');
  const [traditionalFeeBaht, setTraditionalFeeBaht] = useState(null);
  const [cryptoFeeBaht, setCryptoFeeBaht] = useState(null);

  const exchangeRate = 35; // 1 USD = 35 Baht
  const isMobile = useMediaQuery('(max-width:600px)'); // Detectar pantallas pequeñas

  const handleCalculate = () => {
    const parsedAmountUSD = parseFloat(amountUSD.replace(/\./g, '')); // Eliminar puntos de miles
    if (isNaN(parsedAmountUSD) || parsedAmountUSD <= 0) {
      alert('Please enter a valid amount in USD.');
      return;
    }

    const amountBaht = parsedAmountUSD * exchangeRate;

    // Calculando las comisiones en Baht
    const atmFeeBaht = 220;
    const bankFeeBaht = amountBaht * 0.03; // 3%
    const creditCardFeeBaht = amountBaht * 0.05; // 5%
    const calculatedTraditionalFeeBaht = atmFeeBaht + bankFeeBaht + creditCardFeeBaht;

    const cryptoTransactionFeeBaht = amountBaht * 0.002; // 0.2%
    const bitkubExchangeFeeBaht = amountBaht * 0.0025; // 0.25%
    const calculatedCryptoFeeBaht = cryptoTransactionFeeBaht + bitkubExchangeFeeBaht;

    setTraditionalFeeBaht(calculatedTraditionalFeeBaht.toFixed(2));
    setCryptoFeeBaht(calculatedCryptoFeeBaht.toFixed(2));
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE').format(number); // Formato para separar miles con puntos
  };

  const handleInputChange = (e) => {
    const rawValue = e.target.value.replace(/\./g, ''); // Remover puntos existentes
    if (!isNaN(rawValue)) {
      setAmountUSD(formatNumber(rawValue));
    }
  };

  return (
    <Box sx={{ py: 8, px: 4, backgroundColor: '#F5FAFF' }}>
      {/* Título */}
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 700,
          color: '#007BFF',
          mb: 4,
          textTransform: 'uppercase',
        }}
      >
        Fee Calculator
      </Typography>

      {/* Descripción */}
      <Typography
        variant="body1"
        align="center"
        sx={{
          mb: 6,
          color: '#555',
          maxWidth: 800,
          margin: '0 auto',
        }}
      >
        Compare fees between traditional payments and cryptocurrency payments.
      </Typography>

      {/* Input y botón */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 6,
          gap: 2,
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Enter amount in USD"
          value={amountUSD}
          onChange={handleInputChange}
          sx={{
            width: isMobile ? '100%' : 300,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#007BFF',
              },
              '&:hover fieldset': {
                borderColor: '#0056b3',
              },
            },
          }}
        />
        <Button
          variant="contained"
          onClick={handleCalculate}
          sx={{
            background: 'linear-gradient(135deg, #FFD700, #FFC300)',
            color: '#000',
            fontWeight: 'bold',
            fontSize: '1rem',
            textTransform: 'none',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            '&:hover': {
              background: 'linear-gradient(135deg, #007BFF, #0056b3)',
              color: '#FFF',
            },
            padding: '10px 20px',
          }}
        >
          Calculate
        </Button>
      </Box>

      {/* Resultados */}
      {traditionalFeeBaht !== null && cryptoFeeBaht !== null && (
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="body1">
            Traditional Payment Fee: <strong>{formatNumber(traditionalFeeBaht)} Baht ({formatNumber((traditionalFeeBaht / exchangeRate).toFixed(2))} USD)</strong>
          </Typography>
          <Typography variant="body1">
            Cryptocurrency Payment Fee: <strong>{formatNumber(cryptoFeeBaht)} Baht ({formatNumber((cryptoFeeBaht / exchangeRate).toFixed(2))} USD)</strong>
          </Typography>
          <Typography variant="body1">
            You save: <strong>{formatNumber((traditionalFeeBaht - cryptoFeeBaht).toFixed(2))} Baht ({formatNumber(((traditionalFeeBaht - cryptoFeeBaht) / exchangeRate).toFixed(2))} USD)</strong> by using crypto!
          </Typography>
        </Box>
      )}

      {/* Tabla completa */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: '#007BFF' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Method</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Fee Rate</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Calculated Fee (Baht / USD)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>ATM Withdrawal</TableCell>
              <TableCell>Flat fee for cash withdrawal</TableCell>
              <TableCell>220 BATH</TableCell>
              <TableCell>220 BATH / {formatNumber((220 / exchangeRate).toFixed(2))} USD</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bank Transfer</TableCell>
              <TableCell>Percentage of total amount</TableCell>
              <TableCell>3%</TableCell>
              <TableCell>
                {amountUSD
                  ? `${formatNumber((amountUSD.replace(/\./g, '') * exchangeRate * 0.03).toFixed(2))} BATH / ${formatNumber((amountUSD.replace(/\./g, '') * 0.03).toFixed(2))} USD`
                  : '0 BATH / 0 USD'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Credit Card</TableCell>
              <TableCell>Percentage of total amount</TableCell>
              <TableCell>5%</TableCell>
              <TableCell>
                {amountUSD
                  ? `${formatNumber((amountUSD.replace(/\./g, '') * exchangeRate * 0.05).toFixed(2))} BATH / ${formatNumber((amountUSD.replace(/\./g, '') * 0.05).toFixed(2))} USD`
                  : '0 BATH / 0 USD'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cryptocurrency (USDT)</TableCell>
              <TableCell>Transaction fee</TableCell>
              <TableCell>0.2%</TableCell>
              <TableCell>
                {amountUSD
                  ? `${formatNumber((amountUSD.replace(/\./g, '') * exchangeRate * 0.002).toFixed(2))} BATH / ${formatNumber((amountUSD.replace(/\./g, '') * 0.002).toFixed(2))} USD`
                  : '0 BATH / 0 USD'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Exchange Conversion (Bitkub)</TableCell>
              <TableCell>Conversion to Thai Baht</TableCell>
              <TableCell>0.25%</TableCell>
              <TableCell>
                {amountUSD
                  ? `${formatNumber((amountUSD.replace(/\./g, '') * exchangeRate * 0.0025).toFixed(2))} BATH / ${formatNumber((amountUSD.replace(/\./g, '') * 0.0025).toFixed(2))} USD`
                  : '0 BATH / 0 USD'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default FeeCalculator;
