import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Avatar } from '@mui/material';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const stepsData = [
  {
    title: "Download the Bitkub App",
    description: "Get the app from the App Store or Google Play.",
    icon: <MobileFriendlyIcon sx={{ fontSize: 40, color: '#00C26D' }} />,
  },
  {
    title: "Create an Account",
    description: "Sign up for a Bitkub account with your information.",
    icon: <AccountCircleIcon sx={{ fontSize: 40, color: '#009F5E' }} />,
  },
  {
    title: "Verify Your Identity",
    description: "Complete KYC to secure your account and enable transactions.",
    icon: <VerifiedUserIcon sx={{ fontSize: 40, color: '#00C26D' }} />,
  },
];

function InstallationSteps() {
  return (
    <Box
      sx={{
        py: 8,
        px: 3,
        background: 'linear-gradient(180deg, #F9FAFB, #E0F7F1)',
        textAlign: 'center',
      }}
    >
      {/* Título */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          color: '#009F5E',
          mb: 6,
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Getting Started with Bitkub
      </Typography>

      {/* Grid de pasos */}
      <Grid container spacing={4} justifyContent="center">
        {stepsData.map((step, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                borderRadius: 4,
                boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
                },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  p: 3,
                }}
              >
                {/* Icono */}
                <Avatar
                  sx={{
                    bgcolor: 'transparent',
                    width: 64,
                    height: 64,
                    mb: 2,
                  }}
                >
                  {step.icon}
                </Avatar>

                {/* Título del paso */}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#00C26D',
                    fontFamily: "'Poppins', sans-serif",
                    mb: 2,
                  }}
                >
                  {step.title}
                </Typography>

                {/* Descripción */}
                <Typography
                  variant="body2"
                  sx={{
                    color: '#555',
                    fontFamily: "'Poppins', sans-serif",
                    lineHeight: 1.6,
                  }}
                >
                  {step.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default InstallationSteps;
