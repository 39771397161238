import React, { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

function FAQ() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqItems = t('faq.questions', { returnObjects: true }); // Obtener dinámicamente las preguntas

  return (
    <Box
      sx={{
        backgroundColor: '#F9FAFB',
        padding: '60px 20px',
        textAlign: 'center',
      }}
    >
      {/* Título */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          color: '#007BFF',
          marginBottom: '40px',
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        {t('faq.title')}
      </Typography>

      {/* Acordeón de FAQs */}
      <Box sx={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
        {faqItems.map((item) => (
          <Accordion
            key={item.id}
            expanded={expanded === `panel${item.id}`}
            onChange={handleChange(`panel${item.id}`)}
            sx={{
              backgroundColor: '#FFFFFF',
              border: '1px solid #E0E0E0',
              borderRadius: '8px',
              marginBottom: '16px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              '&:before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#007BFF' }} />}
              aria-controls={`panel${item.id}-content`}
              id={`panel${item.id}-header`}
              sx={{
                padding: '16px',
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                },
                '&:hover': {
                  backgroundColor: '#F0F4F8',
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  color: '#333',
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '16px', color: '#555', fontFamily: "'Poppins', sans-serif" }}>
              <Typography variant="body1">{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}

export default FAQ;
