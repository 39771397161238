import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';

const benefitsData = [
  { icon: "🔒", title: "Secure Transactions", description: "Your payments are protected and secure." },
  { icon: "⚡", title: "Low Fees", description: "Enjoy low transaction fees compared to traditional methods." },
  { icon: "💸", title: "Instant THB Conversion", description: "Convert crypto to baht instantly." },
  { icon: "📱", title: "QR Code Payments", description: "Easily accept payments via QR codes." },
];

// Styled component for the icon
const BenefitIcon = styled('span')({
  fontSize: '2.5rem',
  marginBottom: '15px',
  color: '#00C26D', // Verde característico
});

function Benefits() {
  return (
    <Box sx={{ py: 6, px: 3, textAlign: 'center', backgroundColor: '#F9FAFB' }}>
      {/* Título */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          mb: 4,
          color: '#333',
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Why Choose Bitkub?
      </Typography>

      {/* Grid de beneficios */}
      <Grid container spacing={4} justifyContent="center">
        {benefitsData.map((benefit, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            {/* Card de beneficio */}
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                p: 3,
                border: '2px solid #00C26D',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                },
              }}
            >
              <CardContent>
                {/* Icono */}
                <BenefitIcon>{benefit.icon}</BenefitIcon>

                {/* Título */}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#333',
                    mb: 1,
                  }}
                >
                  {benefit.title}
                </Typography>

                {/* Descripción */}
                <Typography
                  variant="body2"
                  sx={{
                    color: '#666',
                  }}
                >
                  {benefit.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Benefits;
