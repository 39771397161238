import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Home as HomeIcon, Info as InfoIcon, ContactMail as ContactIcon, AccountCircle as AccountIcon, AccountBalanceWallet as AccountBalanceWalletIcon } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

const Header = ({ language, setLanguage }) => {
  const { t } = useTranslation();
  const location = useLocation(); // Hook para obtener la URL actual
  const [value, setValue] = useState(null); // Inicialmente, ningún valor seleccionado

  // Colores globales
  const colors = {
    primaryBlue: '#007BFF',
    secondaryYellow: '#FFD700',
    bitkubGreen: '#00B894',
    textDefault: '#FFFFFF',
  };

  // Menú de navegación
  const navItems = [
    { label: t('header.home'), value: 'home', icon: <HomeIcon />, to: '/' },
    { label: t('header.about'), value: 'about', icon: <InfoIcon />, to: '/about' },
    {
      label: t('header.bitkub'),
      value: 'bitkub',
      icon: <AccountBalanceWalletIcon />,
      to: '/bitkub',
      selectedColor: colors.bitkubGreen,
      labelColor: colors.bitkubGreen, // Color del texto del label para Bitkub
    },
    { label: t('header.contact'), value: 'contact', icon: <ContactIcon />, to: '/contact' },
    { label: t('header.login'), value: 'login', icon: <AccountIcon />, to: '/login' },
  ];

  // Sincroniza la URL actual con el estado
  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = navItems.find((item) => item.to === currentPath);

    if (currentItem) {
      setValue(currentItem.value); // Selecciona el valor correspondiente
    } else {
      setValue(null); // Si no hay coincidencia, deselecciona todos los íconos
    }
  }, [location.pathname, navItems]);

  return (
    <>
      {/* Barra superior para escritorio */}
      <AppBar position="fixed" sx={{ backgroundColor: colors.primaryBlue, boxShadow: 2, display: { xs: 'none', md: 'flex' } }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* Logo */}
          <Typography
            variant="h6"
            component={Link}
            to="/"
            sx={{
              textDecoration: 'none',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 800,
              fontSize: '2.2rem',
              letterSpacing: '0.5px',
            }}
          >
            <span style={{ color: colors.textDefault }}>Pay</span>
            <span style={{ color: colors.secondaryYellow }}>Thai</span>
          </Typography>

          {/* Navegación */}
          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            {navItems.map((item) => (
              <Typography
                key={item.value}
                component={Link}
                to={item.to}
                sx={{
                  color: colors.textDefault,
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  '&:hover': { color: colors.secondaryYellow },
                }}
              >
                {item.label}
              </Typography>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Selector de idioma */}
      <LanguageSelector language={language} setLanguage={setLanguage} />

      {/* Barra de navegación móvil */}
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
          zIndex: 1300,
        }}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          sx={{
            width: '100%',
            backgroundColor: '#FFFFFF',
            '& .Mui-selected': {
              color: colors.secondaryYellow, // Amarillo predeterminado para seleccionados
            },
          }}
        >
          {navItems.map((item) => (
            <BottomNavigationAction
              key={item.value}
              label={item.label}
              value={item.value}
              icon={item.icon}
              component={Link}
              to={item.to}
              sx={{
                color: colors.primaryBlue,
                '&:hover': {
                  color: item.selectedColor || colors.secondaryYellow, // Hover verde para Bitkub, amarillo para otros
                },
                '&.Mui-selected': {
                  color: item.selectedColor || colors.secondaryYellow, // Cambia el color del icono
                  '& .MuiBottomNavigationAction-label': {
                    color: item.labelColor || colors.secondaryYellow, // Cambia el color del texto del label
                  },
                },
              }}
            />
          ))}
        </BottomNavigation>
      </Box>
    </>
  );
};

export default Header;
