import React, { useState, useEffect } from 'react';
import './ContactForm.css';
import { db } from '../../config/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { toast } from 'react-toastify'; // Librería de notificaciones
import 'react-toastify/dist/ReactToastify.css'; // Importar estilos de react-toastify

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Show success message after form submission
  useEffect(() => {
    if (submitted) {
      toast.success('Your message has been sent successfully!');
    }
  }, [submitted]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Validate form inputs
  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name) newErrors.name = 'Name is required';
    if (!emailPattern.test(formData.email)) newErrors.email = 'Please enter a valid email';
    if (!formData.message) newErrors.message = 'Message cannot be empty';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true); // Start loading

    try {
      // Sending data to Firestore
      await addDoc(collection(db, 'contactMessages'), formData); // Save to Firestore
      setSubmitted(true); // Set submitted state to true
      setFormData({ name: '', email: '', phone: '', subject: '', message: '' }); // Reset form fields
      setErrors({});
      setLoading(false); // Stop loading
      toast.success('Your message has been sent successfully!');
    } catch (error) {
      console.error('Error sending message to Firestore: ', error);
      setLoading(false); // Stop loading
      toast.error('There was an error sending your message. Please try again.');
    }
  };

  return (
    <section className="contact-page-form-section">
      <h2>Contact Us</h2>

      {/* If the form is submitted, show success message only */}
      {submitted ? (
        <div className="success-message">
          Thank you for reaching out! We’ll get back to you soon.
        </div>
      ) : (
        <>
          <p>We’d love to hear from you! Fill out the form below and we’ll get back to you as soon as possible.</p>
          <form className="contact-page-form" onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="form-control"
            />
            {errors.name && <div className="error-message">{errors.name}</div>}

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="form-control"
            />
            {errors.email && <div className="error-message">{errors.email}</div>}

            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="form-control"
            />

            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="form-control"
            />

            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="form-control"
            />
            {errors.message && <div className="error-message">{errors.message}</div>}

            <button type="submit" className="btn-submit" disabled={loading}>
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </form>
        </>
      )}
    </section>
  );
}

export default ContactForm;
