// src/components/PrivacyPolicy/PrivacyPolicy.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <section className="privacy-policy">
      <h2>{t('privacyPolicy.title')}</h2>
      <p>{t('privacyPolicy.lastUpdated')}</p>
      <div className="policy-content">
        
        <h3>{t('privacyPolicy.sections.introduction.title')}</h3>
        <p>{t('privacyPolicy.sections.introduction.content')}</p>

        <h3>{t('privacyPolicy.sections.informationWeCollect.title')}</h3>
        <p>{t('privacyPolicy.sections.informationWeCollect.content')}</p>
        <ul>
          {t('privacyPolicy.sections.informationWeCollect.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h3>{t('privacyPolicy.sections.howWeUseInformation.title')}</h3>
        <p>{t('privacyPolicy.sections.howWeUseInformation.content')}</p>
        <ul>
          {t('privacyPolicy.sections.howWeUseInformation.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h3>{t('privacyPolicy.sections.sharingInformation.title')}</h3>
        <p>{t('privacyPolicy.sections.sharingInformation.content')}</p>
        <ul>
          {t('privacyPolicy.sections.sharingInformation.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h3>{t('privacyPolicy.sections.security.title')}</h3>
        <p>{t('privacyPolicy.sections.security.content')}</p>

        <h3>{t('privacyPolicy.sections.yourRights.title')}</h3>
        <p>{t('privacyPolicy.sections.yourRights.content')}</p>
        <ul>
          {t('privacyPolicy.sections.yourRights.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h3>{t('privacyPolicy.sections.dataRetention.title')}</h3>
        <p>{t('privacyPolicy.sections.dataRetention.content')}</p>

        <h3>{t('privacyPolicy.sections.childrensPrivacy.title')}</h3>
        <p>{t('privacyPolicy.sections.childrensPrivacy.content')}</p>

        <h3>{t('privacyPolicy.sections.policyChanges.title')}</h3>
        <p>{t('privacyPolicy.sections.policyChanges.content')}</p>

        <h3>{t('privacyPolicy.sections.internationalTransfers.title')}</h3>
        <p>{t('privacyPolicy.sections.internationalTransfers.content')}</p>

        <h3>{t('privacyPolicy.sections.contactUs.title')}</h3>
        <p>{t('privacyPolicy.sections.contactUs.content')}</p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
