import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Verificación de configuración de Firebase
if (
  !process.env.REACT_APP_FIREBASE_API_KEY ||
  !process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
  !process.env.REACT_APP_FIREBASE_PROJECT_ID ||
  !process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
  !process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ||
  !process.env.REACT_APP_FIREBASE_APP_ID
) {
  throw new Error("Missing Firebase configuration in .env file");
}

// Configuración de Firebase desde variables de entorno
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);

// Inicializar servicios de Firebase
const auth = getAuth(app);        // Autenticación
const db = getFirestore(app);     // Firestore para almacenamiento de datos
const storage = getStorage(app);  // Almacenamiento de archivos (si es necesario)

// Exportar los servicios necesarios para su uso en otras partes del proyecto
export { auth, db, storage, signOut };
