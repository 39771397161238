import React, { useState } from 'react';
import { Box, Typography, Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PersonalDataManagement = ({ onConsent }) => {
  const { t } = useTranslation();
  const [consents, setConsents] = useState({
    newsConsent: '', // Asegúrate de usar valores predeterminados válidos ('', 'accept', o 'decline')
    productConsent: '',
    partnerConsent: '',
  });

  // Maneja los cambios en los consentimientos
  const handleConsentChange = (key, value) => {
    setConsents((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Verifica si todos los campos tienen un valor
  const isFormComplete = Object.values(consents).every((value) => value !== '');

  const handleSubmit = () => {
    if (isFormComplete && onConsent) {
      onConsent(consents);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: '600px',
        margin: '0 auto',
        padding: '30px',
        border: '1px solid #ddd',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography
        variant="h4"
        sx={{ textAlign: 'center', marginBottom: '20px', color: '#007BFF', fontWeight: 'bold' }}
      >
        {t('register.personal_data_title')}
      </Typography>

      {/* Consentimiento para Noticias */}
      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="body2" sx={{ marginBottom: '10px' }}>
          {t('register.personal_data_news')}
        </Typography>
        <RadioGroup
          row
          name="newsConsent"
          value={consents.newsConsent} // Enlaza con el estado
          onChange={(e) => handleConsentChange('newsConsent', e.target.value)} // Actualiza el estado
        >
          <FormControlLabel value="accept" control={<Radio />} label={t('register.accept')} />
          <FormControlLabel value="decline" control={<Radio />} label={t('register.decline')} />
        </RadioGroup>
      </Box>

      {/* Consentimiento para Productos */}
      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="body2" sx={{ marginBottom: '10px' }}>
          {t('register.personal_data_product')}
        </Typography>
        <RadioGroup
          row
          name="productConsent"
          value={consents.productConsent} // Enlaza con el estado
          onChange={(e) => handleConsentChange('productConsent', e.target.value)} // Actualiza el estado
        >
          <FormControlLabel value="accept" control={<Radio />} label={t('register.accept')} />
          <FormControlLabel value="decline" control={<Radio />} label={t('register.decline')} />
        </RadioGroup>
      </Box>

      {/* Consentimiento para Socios */}
      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="body2" sx={{ marginBottom: '10px' }}>
          {t('register.personal_data_partner')}
        </Typography>
        <RadioGroup
          row
          name="partnerConsent"
          value={consents.partnerConsent} // Enlaza con el estado
          onChange={(e) => handleConsentChange('partnerConsent', e.target.value)} // Actualiza el estado
        >
          <FormControlLabel value="accept" control={<Radio />} label={t('register.accept')} />
          <FormControlLabel value="decline" control={<Radio />} label={t('register.decline')} />
        </RadioGroup>
      </Box>

      {/* Botón de Confirmación */}
      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={!isFormComplete} // Habilita el botón solo si el formulario está completo
        sx={{
          width: '100%',
          marginTop: '20px',
          backgroundColor: '#007BFF',
          '&:hover': { backgroundColor: '#0056b3' },
        }}
      >
        {t('register.confirm')}
      </Button>
    </Box>
  );
};

export default PersonalDataManagement;
