// src/pages/Contact.jsx
import React from 'react';
import ContactForm from '../components/ContactPage/ContactForm';
import ContactInfo from '../components/ContactPage/ContactInfo';
import Map from '../components/ContactPage/Map';

function Contact() {
  return (
    <div>
      <ContactInfo />
      <ContactForm />
      <Map />
    </div>
  );
}

export default Contact;
