import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './AffiliateMap.css';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { Box, Typography, Card, CardContent } from '@mui/material';

// Función para crear un ícono personalizado con un emoji
const createEmojiIcon = (emoji) =>
  L.divIcon({
    html: `<div style="font-size: 20px;">${emoji}</div>`,
    iconSize: [25, 25],
    className: 'emoji-marker',
  });

function AffiliateMap() {
  const defaultCenter = [13.7563, 100.5018]; // Centro aproximado de Tailandia
  const defaultZoom = 4; // Zoom inicial para que se vea toda Tailandia

  // Ejemplo de ubicaciones
  const locations = [
    { id: 1, name: 'Hotel Bangkok', position: [13.7563, 100.5018], details: 'Luxury hotel in the heart of Bangkok', emoji: '🏨' },
    { id: 2, name: 'Phuket Beach Cafe', position: [7.8804, 98.3923], details: 'Seaside cafe with beautiful views', emoji: '☕️' },
    { id: 3, name: 'Chiang Mai Hostel', position: [18.7061, 98.9817], details: 'Budget-friendly hostel in Chiang Mai', emoji: '🏠' },
  ];

  return (
    <Box sx={{ py: 4, px: 2, backgroundColor: '#F5FAFF', textAlign: 'center' }}>
      {/* Título de la sección */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 700,
          color: '#007BFF',
          mb: 4,
          textTransform: 'uppercase',
        }}
      >
        Our Partners in Thailand
      </Typography>

      {/* Contenedor del mapa */}
      <Box
        sx={{
          height: '500px',
          width: '100%',
          maxWidth: '1200px',
          mx: 'auto',
          borderRadius: 3,
          overflow: 'hidden',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <MapContainer
          center={defaultCenter}
          zoom={defaultZoom}
          scrollWheelZoom={false}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <MarkerClusterGroup>
            {locations.map((location) => (
              <Marker
                key={location.id}
                position={location.position}
                icon={createEmojiIcon(location.emoji)}
              >
                <Popup>
                  <Card
                    sx={{
                      minWidth: 200,
                      textAlign: 'center',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                      borderRadius: 2,
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: 'Poppins, sans-serif',
                          fontWeight: 600,
                          color: '#007BFF',
                          mb: 1,
                        }}
                      >
                        {location.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#555',
                          fontFamily: 'Roboto, sans-serif',
                          lineHeight: 1.4,
                        }}
                      >
                        {location.details}
                      </Typography>
                    </CardContent>
                  </Card>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        </MapContainer>
      </Box>
    </Box>
  );
}

export default AffiliateMap;
