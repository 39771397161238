import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid, Paper } from '@mui/material';

const steps = (t) => [
  {
    number: 1,
    title: t('interactiveDemo.step1.title'),
    description: t('interactiveDemo.step1.description'),
  },
  {
    number: 2,
    title: t('interactiveDemo.step2.title'),
    description: t('interactiveDemo.step2.description'),
  },
  {
    number: 3,
    title: t('interactiveDemo.step3.title'),
    description: t('interactiveDemo.step3.description'),
  },
];

const InteractiveDemo = () => {
  const { t } = useTranslation();
  const translatedSteps = steps(t);

  return (
    <Box sx={{ py: 8, px: 4, backgroundColor: '#F5FAFF' }}>
      {/* Título */}
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 700,
          color: '#007BFF',
          mb: 4,
          textTransform: 'uppercase',
          letterSpacing: 2,
        }}
      >
        {t('interactiveDemo.title')}
      </Typography>

      {/* Descripción */}
      <Typography
        variant="body1"
        align="center"
        sx={{
          mb: 8,
          color: '#555',
          maxWidth: 800,
          margin: '0 auto',
          lineHeight: 1.8,
          fontSize: '1.1rem',
        }}
      >
        {t('interactiveDemo.description')}
      </Typography>

      {/* Pasos interactivos */}
      <Grid container spacing={6} justifyContent="center" sx={{ mt: 2 }}>
        {translatedSteps.map((step) => (
          <Grid item xs={12} sm={6} md={4} key={step.number}>
            <Paper
              elevation={4}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
                textAlign: 'center',
                borderRadius: 3,
                p: 4,
                background: 'linear-gradient(135deg, #007BFF, #FFD700)',
                color: 'white',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: 8,
                },
              }}
            >
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: 'white',
                  color: '#007BFF',
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                {step.number}
              </Box>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 'bold',
                  mb: 1.5,
                  textTransform: 'capitalize',
                  fontSize: '1.1rem',
                }}
              >
                {step.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  lineHeight: 1.6,
                  fontSize: '0.95rem',
                  color: '#EAEAEA',
                }}
              >
                {step.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Video */}
      <Box
        sx={{
          display: { xs: 'none', md: 'block' }, // Ocultar en pantallas pequeñas, mostrar en medianas o mayores
          mt: 12,
          position: 'relative',
          pb: '42%', // Mantener proporción de 24:9
          height: 0,
          overflow: 'hidden',
          borderRadius: 4,
          boxShadow: 4,
        }}
      >
        <video
          src={`${process.env.PUBLIC_URL}/assets/videos/Real_Roads.mp4`}
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 'inherit',
          }}
        />
      </Box>
    </Box>
  );
};

export default InteractiveDemo;
