// src/components/ContactPage/ContactInfo.jsx
import React from 'react';
import './ContactInfo.css';

function ContactInfo() {
  return (
    <section className="contact-info container">
      <h2>Our Contact Information</h2>
      <ul className="list-unstyled">
        <li><strong>Address:</strong> 1234 Sukhumvit Road, Bangkok, Thailand</li>
        <li><strong>Phone:</strong> +66 1234 5678</li>
        <li><strong>Email:</strong> info@paythai.io</li>
      </ul>
    </section>
  );
}

export default ContactInfo;
