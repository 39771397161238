import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material'; // Usamos MUI Box, Typography, Button
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

function HeroSection() {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Hook para navegar

  return (
    <Box
      sx={{
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/hero-background.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      {/* Filtro de color oscuro para mejorar la visibilidad del contenido */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      />

      <Container
        sx={{
          position: 'relative',
          textAlign: 'center',
          zIndex: 1,
          maxWidth: 'sm', // Limitar el ancho del contenido para una mejor vista en pantallas grandes
        }}
      >
        {/* Título */}
        <Typography
          variant="h2"
          sx={{
            color: 'white',
            fontWeight: 700,
            fontSize: '3rem',
            marginBottom: '20px',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Agregado sombra al texto para más contraste
          }}
        >
          {t('hero.title')}
        </Typography>

        {/* Descripción */}
        <Typography
          variant="h6"
          sx={{
            color: 'white',
            marginBottom: '30px',
            fontWeight: 400,
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Agregado sombra al texto para mayor legibilidad
          }}
        >
          {t('hero.description')}
        </Typography>

        {/* Botón de llamada a la acción */}
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            backgroundColor: '#FFD700',
            color: 'white',
            borderRadius: '30px',
            padding: '12px 30px',
            fontSize: '1.1rem',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#007BFF', // Cambio de color al pasar el mouse
            },
          }}
          onClick={() => navigate('/bitkub')} // Redirige a la página de Bitkub
        >
          {t('hero.button')}
        </Button>
      </Container>
    </Box>
  );
}

export default HeroSection;
