import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Avatar, Button } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const stepsData = [
  {
    icon: <AccountBalanceWalletIcon sx={{ fontSize: 40, color: '#00C26D' }} />,
    title: "Go to Your Wallet",
    description: "Open the Bitkub app and navigate to your wallet.",
  },
  {
    icon: <CurrencyExchangeIcon sx={{ fontSize: 40, color: '#00B894' }} />,
    title: "Select Cryptocurrency",
    description: "Choose the cryptocurrency you want to convert.",
  },
  {
    icon: <MonetizationOnIcon sx={{ fontSize: 40, color: '#00C26D' }} />,
    title: "Convert to THB",
    description: "Click 'Convert to THB' and confirm the amount.",
  },
  {
    icon: <AccountBalanceIcon sx={{ fontSize: 40, color: '#00B894' }} />,
    title: "Transfer to Bank",
    description: "Transfer your THB balance to your bank account.",
  },
];

function THBConversion() {
  return (
    <Box
      sx={{
        py: 8,
        px: 3,
        textAlign: 'center',
        backgroundColor: '#F9FAFB',
      }}
    >
      {/* Título */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          mb: 5,
          fontSize: '2.2rem',
          background: 'linear-gradient(90deg, #009F5E, #00C26D)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Convert Cryptocurrency to Thai Baht
      </Typography>

      {/* Descripción */}
      <Typography
        variant="body1"
        sx={{
          mb: 6,
          color: '#555',
          fontSize: '1.1rem',
          lineHeight: 1.6,
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Bitkub allows you to convert your cryptocurrency balance to Thai Baht instantly, 
        so you can access your funds in local currency.
      </Typography>

      {/* Grid de pasos */}
      <Grid container spacing={4} justifyContent="center">
        {stepsData.map((step, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                p: 3,
                borderRadius: 3,
                boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
                },
              }}
            >
              <CardContent>
                {/* Icono */}
                <Avatar
                  sx={{
                    bgcolor: 'transparent',
                    width: 64,
                    height: 64,
                    mx: 'auto',
                    mb: 2,
                  }}
                >
                  {step.icon}
                </Avatar>

                {/* Título */}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#00C26D',
                    fontFamily: "'Poppins', sans-serif",
                    mb: 2,
                  }}
                >
                  {step.title}
                </Typography>

                {/* Descripción */}
                <Typography
                  variant="body2"
                  sx={{
                    color: '#555',
                    lineHeight: 1.5,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {step.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Botón */}
      <Button
        variant="contained"
        sx={{
          mt: 6,
          px: 5,
          py: 1.8,
          background: 'linear-gradient(90deg, #009F5E, #00C26D)',
          color: '#FFFFFF',
          fontWeight: 'bold',
          fontSize: '1.1rem',
          borderRadius: '30px',
          '&:hover': {
            background: 'linear-gradient(90deg, #00C26D, #009F5E)',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
          },
        }}
        onClick={() => window.location.href = '/conversion-guide'}
      >
        Get Started with Conversion
      </Button>
    </Box>
  );
}

export default THBConversion;
