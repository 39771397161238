import React, { useState } from 'react';
import { Box, TextField, MenuItem, Button, Typography, Grid, CircularProgress, Alert } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { db } from '../../config/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

const industries = [
  'Retail',
  'Hospitality',
  'Food & Beverage',
  'Health & Wellness',
  'Education',
  'Real Estate',
];

const ContactForm = () => {
  const { t } = useTranslation();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      businessName: '',
      ownerName: '',
      email: '',
      phone: '',
      industry: '',
      province: '',
      district: '',
      address: '',
      website: '',
      description: '',
    },
    validationSchema: Yup.object({
      businessName: Yup.string().required(t('form.validation.required')),
      ownerName: Yup.string().required(t('form.validation.required')),
      email: Yup.string().email(t('form.validation.invalid_email')).required(t('form.validation.required')),
      phone: Yup.string().required(t('form.validation.required')),
      industry: Yup.string().required(t('form.validation.required')),
      province: Yup.string().required(t('form.validation.required')),
      district: Yup.string().required(t('form.validation.required')),
      address: Yup.string().required(t('form.validation.required')),
      website: Yup.string().url(t('form.validation.invalid_url')).nullable(),
      description: Yup.string().required(t('form.validation.required')),
    }),
    onSubmit: async (values) => {
      if (!captchaVerified) {
        alert(t('form.validation.captcha'));
        return;
      }

      try {
        await addDoc(collection(db, 'businesses'), values);
        setSubmitted(true);
        formik.resetForm();
      } catch (error) {
        console.error('Error:', error);
      }
    },
  });

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  if (submitted) {
    return (
      <Box sx={{ textAlign: 'center', padding: '20px' }}>
        <Alert severity="success" sx={{ mb: 2 }}>
          {t('form.success')}
        </Alert>
        <Typography variant="h6">{t('form.success_message')}</Typography>
      </Box>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '40px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        maxWidth: '800px',
        margin: '0 auto',
      }}
    >
      <Typography variant="h5" sx={{ mb: 3, color: '#007BFF', textAlign: 'center', fontWeight: 'bold' }}>
        {t('form.title')}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('form.business_name')}
            name="businessName"
            value={formik.values.businessName}
            onChange={formik.handleChange}
            error={formik.touched.businessName && Boolean(formik.errors.businessName)}
            helperText={formik.touched.businessName && formik.errors.businessName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('form.owner_name')}
            name="ownerName"
            value={formik.values.ownerName}
            onChange={formik.handleChange}
            error={formik.touched.ownerName && Boolean(formik.errors.ownerName)}
            helperText={formik.touched.ownerName && formik.errors.ownerName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('form.email')}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            international
            defaultCountry="TH"
            value={formik.values.phone}
            onChange={(value) => formik.setFieldValue('phone', value)}
            className={formik.touched.phone && formik.errors.phone ? 'error' : ''}
          />
          {formik.touched.phone && formik.errors.phone && (
            <Typography variant="caption" color="error">
              {formik.errors.phone}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label={t('form.industry')}
            name="industry"
            value={formik.values.industry}
            onChange={formik.handleChange}
            error={formik.touched.industry && Boolean(formik.errors.industry)}
            helperText={formik.touched.industry && formik.errors.industry}
          >
            {industries.map((industry) => (
              <MenuItem key={industry} value={industry}>
                {t(`form.industry_options.${industry.toLowerCase()}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('form.description')}
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('form.province')}
            name="province"
            value={formik.values.province}
            onChange={formik.handleChange}
            error={formik.touched.province && Boolean(formik.errors.province)}
            helperText={formik.touched.province && formik.errors.province}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('form.district')}
            name="district"
            value={formik.values.district}
            onChange={formik.handleChange}
            error={formik.touched.district && Boolean(formik.errors.district)}
            helperText={formik.touched.district && formik.errors.district}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('form.address')}
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('form.website')}
            name="website"
            value={formik.values.website}
            onChange={formik.handleChange}
            error={formik.touched.website && Boolean(formik.errors.website)}
            helperText={formik.touched.website && formik.errors.website}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, textAlign: 'center' }}>
        <ReCAPTCHA
          sitekey="YOUR_RECAPTCHA_SITE_KEY"
          onChange={handleCaptchaChange}
        />
      </Box>

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting || !captchaVerified}
          startIcon={formik.isSubmitting && <CircularProgress size={20} />}
        >
          {formik.isSubmitting ? t('form.submitting') : t('form.submit')}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;
