import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LanguageSelector = ({ language, setLanguage }) => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage); // Cambia el idioma globalmente
  };

  return (
    <Select
      value={language}
      onChange={handleLanguageChange}
      sx={{
        position: 'fixed',
        top: { xs: '10px', md: '50%' }, // En móvil se mantiene arriba, en escritorio se mueve al centro
        right: '10px', // Siempre en el lado derecho
        transform: { xs: 'none', md: 'translateY(-50%)' }, // Sin transformación en móvil, en escritorio centrado
        backgroundColor: 'rgba(255, 215, 0, 0.7)', // Amarillo translúcido con un poco más de opacidad
        color: 'white', // Cambiado el color de texto a blanco
        fontWeight: '600',
        fontSize: '0.9rem',
        borderRadius: '5px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // Sombra más suave
        zIndex: 1500, // Asegura que el selector esté por encima de otros elementos
        '&:hover': {
          backgroundColor: 'rgba(0, 123, 255, 0.9)', // Azul con un poco más de opacidad
          color: 'white', // Cambia el texto a blanco cuando pasa el ratón
        },
        transition: 'background-color 0.3s ease, color 0.3s ease', // Transición suave
        display: { xs: 'block', md: 'block' }, // Mostrar en dispositivos móviles y escritorio
      }}
    >
      <MenuItem value="en">EN</MenuItem>
      <MenuItem value="th">TH</MenuItem>
    </Select>
  );
};

export default LanguageSelector;
