// src/components/UserProfile.js

import React from 'react';
import { useAuth } from '../context/AuthContext';

function UserProfile() {
  const { user } = useAuth();

  return (
    <div>
      <h2>Perfil de Usuario</h2>
      {user ? (
        <div>
          <p>Email: {user.email}</p>
          <p>UID: {user.uid}</p>
          {/* Aquí puedes agregar más detalles del perfil si deseas */}
        </div>
      ) : (
        <p>No se encontró información del usuario.</p>
      )}
    </div>
  );
}

export default UserProfile;
