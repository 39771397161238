// src/pages/Home.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import HeroSection from '../components/HeroSection/HeroSection';
import UseCasesSection from '../components/UseCasesSection/UseCasesSection';
import InteractiveDemo from '../components/InteractiveDemo/InteractiveDemo';
import FeeCalculator from '../components/FeeCalculator/FeeCalculator';
import AffiliateMap from '../components/AffiliateMap/AffiliateMap';
import BlogSection from '../components/BlogSection/BlogSection';
import FAQ from '../components/FAQ/FAQ';
import Contact from '../components/ContactForm/ContactForm';

function Home() {
  const { t } = useTranslation();

  return (
    <div>
      <HeroSection title={t('hero.title')} description={t('hero.description')} />
      <UseCasesSection title={t('useCases.title')} />
      <InteractiveDemo title={t('interactiveDemo.title')} />
      <FeeCalculator title={t('feeCalculator.title')} />
      <AffiliateMap title={t('affiliateMap.title')} />
      <BlogSection title={t('blogSection.title')} />
      <FAQ title={t('faq.title')} />
      <Contact title={t('contact.title')} />
      
      {/* Puedes seguir agregando otras secciones aquí */}
    </div>
  );
}

export default Home;
