import React from 'react';
import { Box, Typography } from '@mui/material';

const UserAgreement = () => {
  return (
    <Box
      sx={{
        maxWidth: '800px',
        margin: '0 auto',
        padding: '30px',
        border: '1px solid #ddd',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        overflowY: 'auto',
        maxHeight: '80vh',
      }}
    >
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '20px', color: '#007BFF', fontWeight: 'bold' }}>
        PayThai User Agreement
      </Typography>
      <Typography variant="body2" sx={{ whiteSpace: 'pre-line', textAlign: 'justify' }}>
        Last updated: November 20, 2024

        1. Effect of the User Agreement
        This User Agreement ("Agreement") is a binding contract between PayThai Co., Ltd. (the "Company") 
        and the individual or entity registering to use PayThai services (the "User"). By accessing or using 
        PayThai services, the User agrees to comply with this Agreement.

        2. Registration and Account Opening
        2.1. Users must provide accurate personal information during registration and maintain the accuracy of 
        such information.
        2.2. The Company reserves the right to verify user identities and may suspend or terminate accounts 
        for providing false or incomplete information.

        3. Use of Services on the Company’s Service Platform
        3.1. The PayThai platform facilitates crypto payments, conversions, and secure wallet integration 
        for businesses and individuals.
        3.2. Users must comply with the laws of Thailand, including the Digital Asset Act and other 
        applicable regulations.
        3.3. The platform may only be used for lawful purposes.

        4. Listing and Delisting of Digital Assets
        4.1. PayThai supports specific digital assets such as BTC, ETH, ADA, and USDT.
        4.2. The Company reserves the right to list or delist digital assets based on market conditions, legal 
        compliance, and other factors.

        5. Digital Assets Wallet Service
        5.1. Users can connect their external wallets to the PayThai platform for transactions.
        5.2. Wallet security is the User's responsibility; PayThai does not store private keys.

        6. Thai Baht Wallet Service
        6.1. Users can convert digital assets to Thai Baht (THB) through PayThai’s integrated partner services.
        6.2. Conversion rates are determined by market rates and service fees.

        7. Trading and Exchanging Service
        7.1. PayThai allows conversion of supported cryptocurrencies to stablecoins or fiat currencies but 
        does not provide exchange-like services.
        7.2. The User must comply with all applicable anti-money laundering (AML) and know-your-customer (KYC) 
        requirements.

        8. Digital Assets Withdrawal or Transfer Service
        8.1. Users can withdraw cryptocurrencies to external wallets, subject to network fees and limits.
        8.2. Withdrawal processing times depend on network traffic and verification processes.

        9. Digital Assets Deposit Service
        9.1. PayThai allows Users to deposit supported cryptocurrencies into connected wallets for transactions.
        9.2. Deposits must comply with all AML/KYC guidelines.

        10. Digital Assets Storage
        10.1. PayThai does not provide custody services. Users are responsible for their wallet security.
        10.2. The Company shall not be liable for wallet breaches or loss of private keys.

        11. Fees, Service Charges, and Expenses
        11.1. The Company charges a transaction fee of 2% on all crypto payments processed via PayThai.
        11.2. Users are responsible for any additional blockchain network fees during withdrawals or transfers.

        12. Suspension, Termination, and Cancellation
        12.1. The Company may suspend or terminate accounts in cases of fraud, regulatory violations, or 
        breach of this Agreement.
        12.2. Users may cancel their accounts by submitting a formal request.

        13. Limitation of Liability
        13.1. The Company is not liable for losses resulting from unauthorized access, regulatory changes, or 
        third-party actions.
        13.2. Users agree to use the platform at their own risk.

        14. Liability and Indemnification
        14.1. Users shall indemnify the Company for damages caused by their misuse of the platform.
        14.2. The Company shall indemnify Users for losses caused by technical errors or platform-related issues.

        15. Limitations of Transactions
        15.1. PayThai may impose daily, weekly, or monthly transaction limits.
        15.2. These limits are subject to change based on regulatory requirements and risk assessments.

        16. Relationship between the Parties
        16.1. Nothing in this Agreement creates a partnership, joint venture, or employment relationship 
        between the Company and Users.
        16.2. Users act as independent entities while using the platform.

        17. Unclaimed Assets
        17.1. Unclaimed crypto assets after two years of inactivity may be transferred to a government-authorized 
        entity, as required by Thai law.

        18. Prohibited Uses
        18.1. Users may not use PayThai for illegal activities, including money laundering, fraud, or funding 
        terrorism.
        18.2. Violations will result in account termination and legal action.

        19. Force Majeure
        19.1. The Company is not liable for failures caused by natural disasters, wars, regulatory changes, or 
        other unforeseen events beyond its control.

        20. Currencies Available
        20.1. PayThai supports cryptocurrencies such as BTC, ETH, ADA, and USDT, along with Thai Baht.
        20.2. The Company reserves the right to update the list of supported currencies at any time.

        21. Termination of the User Agreement
        21.1. This Agreement may be terminated by either party with 30 days' written notice.
        21.2. Termination does not absolve Users of outstanding fees or liabilities.

        22. Governing Law
        22.1. This Agreement is governed by the laws of Thailand.
        22.2. Users agree to comply with Thai regulatory frameworks, including AML/KYC laws.

        23. Dispute Resolution by Arbitration
        23.1. Disputes shall be resolved through binding arbitration in Thailand.
        23.2. Arbitration costs shall be shared equally by the parties.

        24. General Requirements
        24.1. Users must ensure the security of their devices and accounts while using PayThai services.
        24.2. Any changes to this Agreement will be communicated to Users in advance.
      </Typography>
    </Box>
  );
};

export default UserAgreement;
