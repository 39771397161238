// src/components/AnalyticsTracker.jsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-C327ZLCJ6H', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return null; // No renderiza nada en la interfaz
}

export default AnalyticsTracker;
