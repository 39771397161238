import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Link as MuiLink,
  CircularProgress,
  InputAdornment,
  IconButton,
  Divider,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import ReCAPTCHA from 'react-google-recaptcha';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [captchaValidated, setCaptchaValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValidated(!!value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!captchaValidated) {
      setError(t('login.validation.captcha'));
      setIsLoading(false);
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      navigate('/');
    } catch (err) {
      setError(t(`login.errors.${err.code}`) || t('login.errors.default'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#FFFFFF',
        padding: '20px',
        gap: '40px',
      }}
    >
      {/* Left side - Visual information */}
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          padding: '40px',
          backgroundColor: '#F9FAFB',
          borderRadius: '12px',
          maxWidth: '450px',
          
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: '#007BFF',
            marginBottom: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Welcome to PayThai
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#4F4F4F',
            textAlign: 'center',
            maxWidth: '300px',
            lineHeight: '1.6',
          }}
        >
          Seamlessly manage your cryptocurrency payments with a secure and modern platform.
        </Typography>
        <Box
          component="img"
          src={`${process.env.PUBLIC_URL}/assets/images/login-illustration.png`}
          alt="Login Illustration"
          sx={{ maxWidth: '100%', marginTop: '20px' }}
        />
      </Box>

      {/* Right side - Login form */}
      <Box
        sx={{
          maxWidth: '400px',
          width: '100%',
          padding: '30px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: '20px',
            color: '#007BFF',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Log in to PayThai
        </Typography>

        {error && (
          <Typography
            variant="body2"
            sx={{
              color: '#FF4D4F',
              marginBottom: '15px',
              textAlign: 'center',
            }}
          >
            {error}
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            name="email"
            label={t('login.email')}
            value={formData.email}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            autoComplete="email"
            required
          />
          <TextField
            fullWidth
            name="password"
            label={t('login.password')}
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            autoComplete="current-password"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPasswordToggle} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ marginY: '20px' }}>
            <ReCAPTCHA
              sitekey="YOUR_RECAPTCHA_SITE_KEY"
              onChange={handleCaptchaChange}
            />
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            sx={{
              padding: '10px 0',
              fontSize: '1rem',
              fontWeight: 'bold',
              backgroundColor: '#007BFF',
              '&:hover': {
                backgroundColor: '#0056b3',
              },
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              t('login.submit')
            )}
          </Button>
        </form>

        <Divider sx={{ marginY: '20px' }} />

        <Box sx={{ textAlign: 'center' }}>
          <MuiLink
            component={Link}
            to="/register"
            sx={{ display: 'block', marginBottom: '8px', color: '#007BFF', fontWeight: 'bold' }}
          >
            {t('login.register')}
          </MuiLink>
          <MuiLink
            component={Link}
            to="/forgot-password"
            sx={{ color: '#FFD700', fontWeight: 'bold' }}
          >
            {t('login.forgot_password')}
          </MuiLink>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
